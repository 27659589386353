<template>
  <ag-card-container
    :search.sync="search"
    title="Liste des utilisateurs"
    fluid
  >
    <template #left>
      <v-btn
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>

    <user-list
      :value="userList"
      :selected.sync="selectedUserList"
      :loading="loadingData"
      :search="search"
    >
      <template #footer>
        <v-btn
          color="accent"
          outlined
          small
          append
          to="restore"
        >
          éléments supprimés
        </v-btn>
      </template>
    </user-list>

    <ag-float-button-container>
      <ag-confirm-dialog
        v-if="selectedUserList.length"
        title="Confirmation"
        :message="removeMessage"
        cancel-label="Annuler"
        confirm-label="Supprimer"
        validation-btn-color="error"
        @confirm="removeSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRemove"
            color="error"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>delete</v-icon>
            <v-chip
              x-small
              class="px-1 float-chip font-weight-black"
              color="white"
              text-color="error"
              v-text="selectedUserList.length"
            />
          </v-btn>
        </template>
      </ag-confirm-dialog>
      <v-btn
        to="create"
        append
        color="accent"
        fab
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>

    <!-- RouterView for modals -->
    <router-view />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import UserList from '../components/UserList'

export default {
  name: 'UserListContainer',
  components: {
    AgCardContainer,
    AgFloatButtonContainer,
    AgConfirmDialog,
    UserList,
  },
  data: () => ({
    userList: [],
    selectedUserList: [],
    search: '',
    loadingData: false,
    loadingRemove: false,
    loadingExport: false,
  }),
  computed: {
    ...mapGetters('me', {
      userId: 'userId',
    }),
    removeMessage () {
      const count = this.selectedUserList.length
      return `Souhaitez-vous supprimer ${count} utilisateur(s)`
    },
    hasCurrentUserInSelected () {
      return this.selectedUserList
        .some(user => user._id === this.userId)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'UserList' || !this.userList.length) {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.userList = await Repositories.user.getList({ archived: false })
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async removeSelected () {
      if (this.hasCurrentUserInSelected) {
        this.showErrorNotification('Vous ne pouvez pas vous supprimer')
      }
      else {
        try {
          this.loadingRemove = true
          const count = this.selectedUserList.length
          await Repositories.user.delete(this.selectedUserList.map(select => select._id))
          this.showSuccessNotification(`Vous avez supprimé ${count} utilisateur(s)`)
        }
        catch (error) {
          const notification = Repositories.parseErrorMessage(error)
          this.showErrorNotification(notification)
        }
        finally {
          this.loadingRemove = false
          this.selectedUserList = []
          this.fetchData()
        }
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Nom', key: 'firstname' },
            { header: 'Prénom', key: 'lastname' },
            { header: 'Login', key: 'login' },
            { header: 'Rôle', key: 'roleName' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
          sheetName: 'Liste des utilisateurs',
        }
        const data = this.userList.map(item => ({
          _id: item._id,
          firstname: item.firstname,
          lastname: item.lastname,
          login: item.login,
          roleName: item.role.name,
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des utilisateurs')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
